import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import queryAllHomeImages from 'src/queries/queryAllHomeImages'
import App from 'src/components/App/App'
import Head from 'src/components/Head/Head'
import Header from 'src/components/Header/Header'
import Button from 'src/components/Button/Button'
import Paper from '../components/Paper/Paper'
import styles from './index.module.scss'

const Home = () => {
  const nodes = queryAllHomeImages()

  return (
    <App>
      <Head />
      <Header pageTitle="Cardinal Blue Software" />
      <main className={styles.main}>
        <article className={styles.content}>
          <Link to="/products" className={styles.imageWrapper}>
            <Paper>
              <Img
                fluid={nodes[0].childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                className={styles.contentImg}
              />
            </Paper>
          </Link>
          <section className={styles.contentInfo}>
            <header className={styles.contentHeader}>
              <a
                href="https://apps.apple.com/us/developer/cardinal-blue/id381057842#see-all/i-phone-apps"
                className={styles.contentLink}
                target="_blank"
              >
                Our Products
              </a>
            </header>

            <p className={styles.contentDesc}>
              We make the world more fun and creative. Our mobile apps help
              people create cool things with their photos and videos. Our top
              app PicCollage has been downloaded by over 200 million people
              around the world. We are continuing to build new products to
              delight and inspire people with magic video editing, animated
              cutouts and more!
            </p>
            <Link to="/products" className={styles.buttonLink}>
              <Button>Find out more</Button>
            </Link>
          </section>
        </article>
        <article className={styles.content}>
          <section className={styles.contentInfo}>
            <header className={styles.contentHeader}>
              <a
                href="https://pic-collage.com/"
                className={styles.contentLink}
                target="_blank"
              >
                Our Company
              </a>
            </header>
            <p className={styles.contentDesc}>
              We build creative products with friendly design and serious
              technology. We keep things focused on the users and apply the
              latest machine learning techniques to launch products that are
              easy and inspiring to use.
              <br />
              Our company name is Cardinal Blue, but we are better known as
              PicCollage. We are a global startup with roots in Silicon Valley
              and a growing team in Taipei.
            </p>
            <Link to="/contact" className={styles.buttonLink}>
              <Button>Contact us</Button>
            </Link>
          </section>
          <Link
            to="/contact"
            className={`${styles.imageWrapper} ${styles.orderFirst}`}
          >
            <Paper>
              <Img
                fluid={nodes[1].childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                className={styles.contentImg}
              />
            </Paper>
          </Link>
        </article>
        <article className={styles.content}>
          <Link to="/careers" className={styles.imageWrapper}>
            <Paper>
              <Img
                fluid={nodes[2].childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                className={styles.contentImg}
              />
            </Paper>
          </Link>

          <section className={styles.contentInfo}>
            <header className={styles.contentHeader}>
              <Link to="/careers" className={styles.contentLink}>
                Careers
              </Link>
            </header>
            <p className={styles.contentDesc}>
              We are a small team with big impact. We’re looking for inventors,
              dreamers and team-builders to join us in our adventure. If you
              want be part of a team that will inspire you, share knowledge, and
              take on big challenges while having fun too --- let’s talk!
            </p>
            <Link to="/careers" className={styles.buttonLink}>
              <Button>View our open positions</Button>
            </Link>
          </section>
        </article>
      </main>
    </App>
  )
}

export default Home
