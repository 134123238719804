import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Button.module.scss'

const Button = props => {
  const { children, className, onClick } = props

  const rootStyle = cx(styles.root, className)

  return (
    <button className={rootStyle} onClick={onClick}>
      {children}
    </button>
  )
}

const noop = () => {}

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
}

Button.defaultProps = {
  className: '',
  onClick: noop
}

export default Button
