import React from 'react'
import cx from 'classnames'
import styles from './Paper.module.scss'

const Paper = props => {
  const rootStyle = cx({
    [styles.root]: true,
    [styles.hoverEffect]: !props.noHover
  })

  return <div className={rootStyle}>{props.children}</div>
}

export default Paper
