import { graphql, useStaticQuery } from 'gatsby'

const queryAllHomeImages = () => {
  const {
    allFile: { nodes }
  } = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: { relativePath: { glob: "home*" } }
          sort: { order: ASC, fields: [name] }
        ) {
          nodes {
            childImageSharp {
              id
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    `
  )

  return nodes
}

export default queryAllHomeImages
